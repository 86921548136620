@import "~antd/dist/antd.css";

#root {
  height: 100%;
}

div.app {
  height: 100%;
  background-color: rgb(223, 254, 252);
}

.ant-message-notice {
  display: flex;
  justify-content: flex-end;
}

/* Sidebar CSS START*/
.trigger {
  padding: 0 10px 0 25px;
  font-size: 23px;
  transition: color 0.3s;
  color: #fff;
}

.ant-drawer-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sidebar CSS END*/

/* Font Family CSS START */
.poppins_regular {
  font-family: PoppinsRegular !important;
}

.poppins_medium {
  font-family: PoppinsMedium !important;
}

.poppins_semi {
  font-family: PoppinsSemiBold !important;
}

.poppins_bold {
  font-family: PoppinsBold !important;
}

/* Font Family CSS END */

/* Theme Button, Input, Date, Title CSS START / */
.ant-btn.ant-btn-default.umoney_btn,
.ant-btn.ant-btn-default.umoney_btn:hover {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
}

.ant-btn.umoney_outlined_btn {
  border-color: #009d93;
  color: #009d93;
  border-radius: 5px;
  cursor: pointer;
}

.umoney_outlined_btn:hover {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
}
.ant-btn.umoney_outlined_btn1 {
  border-color: #009d93;
  color: #009d93;
  border-radius: 5px;
  cursor: pointer;
}
.umoney_outlined_btn1:hover {
  background: #009d93;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
}

.umoney-input,
.urban-select {
  position: relative;
}

.umoney-input span.ant-typography,
.urban-select span.ant-typography {
  position: absolute;
  top: -11px;
  left: 8px;
  z-index: 2;
  background: #fff;
  padding: 0 5px;
}

.umoney-input input:hover,
.umoney-input input:focus,
.urban-select input:hover,
.urban-select input:focus,
.umoney-input
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.umoney-input .ant-input-affix-wrapper:focus {
  border-color: #009d93;
}

.umoney-input
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.umoney-input .ant-input-affix-wrapper:focus {
  z-index: unset;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
  border-color: #009d93;
}

.umoney-input input:focus,
.urban-select input:focus {
  box-shadow: none;
}

.umoney-date,
.umoney-search {
  position: relative;
}

.umoney-date .date_label,
.umoney-search .search_label {
  position: absolute;
  z-index: 2;
  top: -11px;
  left: 8px;
  background-color: #fff;
  padding: 0 5px;
}

.umoney-date .ant-picker:hover,
.umoney-date .ant-picker:focus {
  border-color: #009d93;
  box-shadow: none;
}

.umoney-date .ant-picker-focused {
  border-color: #009d93;
  box-shadow: none;
}

.umoney-search .ant-input-search .ant-input:hover,
.umoney-search .ant-input-search .ant-input:focus,
.umoney-selectBtn .ant-select .ant-select-selector:hover,
.umoney-search .ant-btn,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #009d93 !important;
  box-shadow: none;
}

.umoney-search
  .ant-input-search
  .ant-input-wrapper
  .ant-input-affix-wrapper-focused {
  border-color: #009d93;
  box-shadow: none;
}

.umoney-search .ant-btn:hover,
.ant-btn:focus {
  border-color: #009d93;
  box-shadow: none;
}

.urban_money_outlined_btn {
  color: #009d93;
}

.urban_money_outlined_btn :hover {
  color: #fff;
  border-color: #009d93;
  background-color: #009d93;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input {
  border: 1px solid #009d93;
  border-radius: 3px;
}

.umoney_title {
  margin: 0 !important;
}

.color_title {
  font-size: 18px !important;
  color: rgb(0, 157, 147) !important;
  background: rgb(255, 255, 255);
  padding: 0px 5px;
}

.umoney_page_title {
  color: #fff !important;
  margin: 0 !important;
}

/* / Theme Button, Input, Date, Title CSS END / */

/* / Form Fields CSS START / */

.umoney-form .ant-form-item .ant-form-item-row .ant-form-item-label {
  position: absolute;
  z-index: 2;
  top: -11px;
  left: 8px;
  background-color: #fff;
  padding: 0 5px;
}

.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-label
  label:after {
  display: none;
}

.umoney-form .ant-form-item .ant-form-item-row .ant-form-item-label label {
  height: 0;
  font-weight: 600;
}

.umoney-form .ant-form-item .ant-form-item-row {
  position: relative;
}

.umoney-form
  .ant-form-item.ant-form-item-has-error
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  > :hover,
.umoney-form
  .ant-form-item.ant-form-item-has-error
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  > :hover,
.umoney-form
  .ant-form-item.ant-form-item-has-error
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-open
  > :focus,
.umoney-form
  .ant-form-item.ant-form-item-has-error
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  > :focus {
  border-color: #ff4d4f;
  box-shadow: none;
}

.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  > :hover,
.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  > :hover,
.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select
  .ant-select-open
  > :focus,
.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  > :focus,
.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-picker,
.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  span.ant-input-affix-wrapper-focused {
  border-color: #009d93;
  box-shadow: none;
}

.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input
  .umoney_btn,
.umoney-form
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input
  .umoney_btn:hover {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
}

.ant-form-item {
  margin-bottom: 0;
}

/* / Form Fields CSS END / */

/* / Drawer Menu Css START / */
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-item.ant-menu-item-selected,
.ant-menu-item.ant-menu-item-selected:hover,
.ant-menu-submenu-selected {
  color: #fff;
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
}

.ant-menu-light .ant-menu-submenu .ant-menu-submenu-title:hover i,
.ant-menu-submenu-selected div i {
  color: #fff !important;
}

.ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #000;
}

.ant-menu-submenu-title {
  margin: 0;
}

.ant-menu-inline {
  border-right: none;
}

/* / Drawer Menu Css END / */

/* / Scrollbar CSS START / */
.ant-drawer-body::-webkit-scrollbar {
  width: 8px;
}

.ant-drawer-body::-webkit-scrollbar-track {
  background-color: #fff;
  border: 1px solid #009d93;
  border-radius: 10px;
}

.ant-drawer-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #009d93;
}

.scroll::-webkit-scrollbar {
  width: 6px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #fff;
  border: 1px solid #009d93;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
}

/* Scrollbar CSS END*/

/* / Loader CSS START / */
.um-loader {
  position: relative;
  width: 3.5em;
  height: 3.5em;
  transform: rotate(165deg);
}

.um-loader:before,
.um-loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.um-loader:before {
  animation: before 2s infinite;
}

.um-loader:after {
  animation: after 2s infinite;
}

@keyframes before {
  0% {
    width: 2.5em;
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
      -1em 0.5em rgba(111, 202, 220, 0.75);
  }

  35% {
    width: 5em;
    box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75),
      0 0.5em rgba(111, 202, 220, 0.75);
  }

  70% {
    width: 2.5em;
    box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75),
      1em 0.5em rgba(111, 202, 220, 0.75);
  }

  100% {
    box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75),
      -1em 0.5em rgba(111, 202, 220, 0.75);
  }
}

@keyframes after {
  0% {
    height: 2.5em;
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
      -0.5em -1em rgba(233, 169, 32, 0.75);
  }

  35% {
    height: 5em;
    box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75),
      -0.5em 0 rgba(233, 169, 32, 0.75);
  }

  70% {
    height: 2.5em;
    box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75),
      -0.5em 1em rgba(233, 169, 32, 0.75);
  }

  100% {
    box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75),
      -0.5em -1em rgba(233, 169, 32, 0.75);
  }
}

.um-loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}

/* / Click Loader CSS START / */
.page-loading {
  pointer-events: none;
  background: #ffffff10;
  opacity: 0.5;
}

.click-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #009d93;
  border-left-color: transparent;
  border-bottom: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
    infinite;
  animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #009d93;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
    infinite;
  animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

/* / Click Loader CSS START / */
/* / Loader CSS END / */

/* / Model CSS START / */
.ant-modal-title {
  font-family: PoppinsRegular;
  font-size: 20px;
}

/* / Model CSS END / */

/* / Table & Pagination CSS START / */
.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  thead
  tr
  > th {
  color: #009d93;
  font-family: PoppinsSemiBold;
}

.ant-table-column-sorter,
.ant-table-filter-trigger {
  color: #009d93;
}

.ant-table-filter-trigger.active {
  color: #009d93;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #009d93;
}

.ant-table-filter-dropdown-btns button.ant-btn-link,
.ant-dropdown-menu-item-selected,
.ant-table-filter-dropdown-btns button.ant-btn-link:hover {
  color: #009d93;
}

.ant-table-filter-dropdown-btns button.ant-btn-primary,
.ant-btn-primary,
.ant-btn-default:hover,
.ant-btn-primary:hover {
  color: #fff;
  background-color: #009d93;
}

.ant-btn-primary .anticon {
  color: #fff;
}

.ant-input:focus {
  box-shadow: none;
  border-color: #009d93;
}

.umoney_table {
  box-shadow: 0px 0px 6px #00000029;
}

.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li,
.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li
  > button,
.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li
  .ant-select
  .ant-select-selector {
  border-color: #009d93;
}

.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li.ant-pagination-disabled
  > button {
  border-color: #d9d9d9;
  color: #d9d9d9;
}

.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li
  > a,
.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li
  > button,
.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li
  .ant-select
  .ant-select-selector,
.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li
  .ant-select
  .ant-select-arrow
  span
  svg {
  color: #009d93;
}

.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li.ant-pagination-item-active {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
}

.umoney_table
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-pagination
  > li.ant-pagination-item-active
  > a {
  color: #fff;
}

.umoney_table .ant-pagination-options {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.umoney_table .ant-pagination {
  padding: 0 10px;
}

/* / Table & Pagination CSS END / */

/* ::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
} */

.module_status {
  border-radius: 30px;
  margin-bottom: 5px;
  padding: 5px 25px;
  font-family: PoppinsMedium;
}

/*marign*/
.m-0 {
  margin: 0 !important;
}
.m-10 {
  margin: 10px !important;
}

.m-20 {
  margin: 20px !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

/*padding*/
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}

/* Button*/
.ant-btn.urban_money_btn {
  margin-top: 20px;
}

.ant-btn.urban_money_btn,
.ant-btn.urban_money_filter_btn {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  border: none;
}

/*end button*/
.urban_money_page_content {
  background: #fff;
  border-radius: 0 0 0 15px;
  padding: 20px;
}

.filter_select_border.MuiOutlinedInput-root {
  border-radius: 4px 0 0 4px;
}

.filter_btn_border {
  border-radius: 0 4px 4px 0 !important;
}

.white_div {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.information {
  margin-top: 20px;
}

/* / Border / */

.border-class {
  border: 1px solid #009d93;
  border-radius: 5px;
  margin: 10px 0;
}

.br {
  border-radius: 5px;
}
/*tabs*/

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff !important;
  border: none;
}
.ant-tabs-tab-btn {
  color: #009d93;
  font-size: 20px;
  font-family: PoppinsMedium;
}

.ant-tabs {
  padding: 10px !important;
}

.ant-tabs-tab {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  margin: 5px;
  border-radius: 5px;
  width: 100%;
}

.ant-tabs > .ant-tabs-nav {
  border: 1px solid #009d93;
  border-radius: 5px;
  padding: 5px;
}

/*Collapse*/
.ant-collapse {
  padding: 10px;
}

.ant-collapse > .ant-collapse-item {
  border: 1px solid #009d93;
  border-radius: 5px;
  margin-bottom: 10px;
}

.ant-collapse-content {
  border-top: 1px solid #009d93;
}

.ant-checkbox-inner {
  border: 1px solid #009d93;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #009d93;
  border-color: #009d93;
}

.ant-collapse-header-text {
  color: #009d93;
  font-size: 19px;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #009d93;
}

.d-flex {
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.border-5 {
  border-radius: 5px;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.close-icon {
  position: absolute;
  top: -12px;
  right: -13px;
  background: rgb(255, 0, 0);
  border-radius: 50%;
  padding: 20;
  cursor: pointer;
}
.new_tab {
  position: absolute;
  padding: 5px;
  bottom: 0px;
  background: #009d93;
  color: #fff;
  border-radius: 10%;
  cursor: pointer;
}

.ant-table-thead .ant-table-cell {
  color: rgb(0, 157, 147) !important;
  background: rgb(255, 255, 255);
}

.tablerow {
  font-size: 10px;
  height: 10px;
  max-height: 20px;
}

.ant-table-pagination-right {
  justify-content: flex-start;
}

.ant-table.ant-table-small {
  font-size: 10px;
  background-color: white;
  font: normal normal 600 Poppins;
}

.ant-table-thead > tr > th {
  background-color: white;
  color: #009d93;
}

.call-status {
  background-color: white;
  margin-top: 20px;
}

.call-status-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.datefilter {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: white;
  border: 1px solid #009d93;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: white;
}

.ant-pagination-item-active a {
  color: white;
}

body {
  background-color: #dffefc;
}

.device-change-panel-table {
  font-size: 14px;
  height: 10px;
  max-height: 20px;
}

.ant-pagination-options {
  position: absolute;
  right: 0px;
}

.ant-modal-header {
  background: #009d93 0% 0% no-repeat padding-box;
  height: 50px;
}

.ant-modal-close-x {
  color: #fff;
}

.ant-modal-title {
  color: #ffffff;
}

.ant-modal-body {
  padding-left: 20px;
}

.radio {
  padding-bottom: 10px;
}

.selectdevicecol {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.ant-table-thead {
  color: #009d93;
  font-family: PoppinsSemiBold;
  font-size: 14px;
}

.ant-modal-body {
  padding-bottom: 20px;
}

.stylebtn {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  width: 100%;
  color: white;
  margin-top: 20px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  overflow-wrap: break-word;
}

.title-finder {
  color: black;
  font-family: PoppinsMedium;
}

.btntext-finder {
  color: #009d93;
  font-family: PoppinsMedium;
  width: 230px;
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #009d93;
}

.btntext-all {
  color: #009d93;
  font-family: PoppinsMedium;
}

.primary-device-btn {
  border-radius: 5px;
  border: 1px solid #009d93;
  margin-left: 5px;
}

.anticon-search {
  color: #009d93;
}

.anticon-calendar {
  color: #009d93;
}

.anticon-down {
  color: #009d93;
}

.tag-form {
  background-color: white;
  border: 1px solid #009d93;
  color: #009d93;
  margin-top: 10px;
  font-family: poppins_regular;
  cursor: pointer;
  font-size: 14px;
}

.ant-btn.umoney_modal_filled_btn {
  background: transparent linear-gradient(180deg, #3ea467 0%, #0b90a5 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: 0;
}
.umoney_modal_outlined_btn {
  border-color: #009d93;
  color: #009d93;
  border-radius: 5px;
  cursor: pointer;
}


/*Switch*/
.ant-switch-checked {
  background-color: #3ea467;
}

/*checkbox label*/
.checkbox_label {
  color: #009d93;
  font-size: 16px;
}

.boxshadow-div {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  /* / margin-top:10px; / */
}

.kyc_doc_upload .ant-upload-list .ant-upload {
  width: 100%;
  height: 130px;
  margin: 0;
}

img.kyc-images {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}

/*main header*/
.main-header {
  padding: 0;
  background-color: #009d93;
  border-radius: 15px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ant-tabs-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload.ant-upload-select-picture-card {
  width: 200px;
  height: 200px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  background-color: #dffefc;
  border: 1px dashed #009d93;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 200px;
  height: 200px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}

/* Upload image Modal css */
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border: 1px solid #009d93;
  border-radius: 5px;
}

.faq-cat-upload {
  display: flex;
}

.ant-upload-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* added */
.scroll_bar {
  height: 100vh;
  overflow-y: scroll;
}
.scroll_bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll_bar::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
  border: 1px solid #009d93;
}

.scroll_bar::-webkit-scrollbar-thumb {
  background-color: #009d93;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}


/* Horizontal Scroll bar */

.scroll_horizontal_bar {
  width: 100vw;
  overflow-y: scroll;
}
.scroll_horizontal_bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll_horizontal_bar::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
  border: 1px solid #009d93;
}

.scroll_horizontal_bar::-webkit-scrollbar-thumb {
  background-color: #009d93;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}
/* Modal Wrapper style  */
.modal-wrapper{
  pointer-events: none;
}

/* Transperrent Loader CSS */
.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  max-height: unset !important;
}

/* remove hover from upload file*/
.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
  display: none;
}

.ant-upload-list-item-name{
  width: 200px;
}

.tag-style{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 40px;
  font-size:16px;  
}


.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
}

.ant-radio-checked .ant-radio-inner{
  border-color: #009D93 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: #009D93;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #009D93 ;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("Poppins"),
    url("../src/config/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("Poppins"),
    url("../src/config/assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("Poppins"),
    url("../src/config/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Poppins"),
    url("../src/config/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}